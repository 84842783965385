<template>
  <div class="dialog">
    <div class="dialog-header">
      <p class="dialog-title mb-0">UPDATE THUMBNAIL</p>
    </div>
    <div class="dialog-content"
         style="overflow-y: auto; max-height: 560px;">
      <b-container class="mt-3 text-center">
        <croppa v-model="file"
                accept=".jpeg,.png,.jpg"
                :width="image == 'music-category-thumbnail' ? 255: 400"
                :height="255"
                :placeholder="placeholder"
                placeholder-color="#068bcd"
                :placeholder-font-size="12"
                :prevent-white-space="false"
                canvas-color="transparent"
                :show-remove-button="true"
                remove-button-color="red"
                :remove-button-size="25"
                :show-loading="true"
                :loading-size="25"
                :initial-image="currentImage"
                @file-type-mismatch="onFileTypeMismatch"
                @new-image="updateImage('new')"
                @image-remove="updateImage('removed')">
        </croppa>
        <div class="buttons">
          <i class="fas fa-search-plus icon"
             @click="updateImage('zoomIn')">
          </i>
          <i class="fas fa-search-minus icon"
             @click="updateImage('zoomOut')">
          </i>
        </div>
        <div class="d-flex justify-content-end">
          <b-button class="mt-3"
                    variant="outline-success"
                    :disabled="disableButton || uploading"
                    @click="uploadFile">
            Update
          </b-button>
          <b-button class="mt-3 ml-1"
                    variant="outline-danger"
                    @click="$emit('close')">
            Close
          </b-button>
        </div>

        <!-- Loading -->
        <loading :active.sync="uploading"
                 :is-full-page="true"
                 background-color="#000"
                 color="#068bcd"
                 :opacity="0.3"
                 :width="70"
                 :height="70">
        </loading>
      </b-container>
    </div>
  </div>

</template>

<script>
  const Loading = () => import('vue-loading-overlay');
  import 'vue-croppa/dist/vue-croppa.css';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name  : 'SimpleUpload',
    props : {
      data  : Object,
      image : {
        type     : String,
        required : false,
      },
    },
    data() {
      return {
        file          : null,
        uploading     : false,
        progress      : 0,
        max           : 100,
        currentImage  : this.data.img_url,
        disableButton : true,
        placeholder   : 'Choose an image',
      };
    },
    components : {
      Loading,
    },
    methods : {

      /**
       * Upload File
       */
      uploadFile() {
        this.file.generateBlob(async (blob) => {
          const formData = new FormData();
          formData.append('file', blob, 'image.png');
          formData.append('imageType', this.data.image_type);
          formData.append('id', this.data.id);

          try {
            this.uploading = true;
            const res = await this.$http.post('/api/upload/image', formData, {
              onUploadProgress : e =>
                this.progress = Math.round(e.loaded * 100 / e.total),
            });

            if (res) {
              this.closeModal();
              this.$notify({
                group : 'notif',
                type  : 'success',
                title : 'Success',
                text  : 'Updated image thumbnail successfully',
              });
            }
          } catch (error) {
            this.uploading = false;
            this.disableButton = true;
            this.$notify({
              group : 'notif',
              type  : 'failed',
              title : 'Failed',
              text  : 'Oops! Something went wrong!',
            });
          }
        }, 'image/jpeg');
      },

      /**
       * Image Update (Croppa) Handler
       *
       * @param state
       */
      updateImage(state) {
        switch (state) {
        case 'new':
          this.disableButton = false;
          break;
        case 'zoomIn':
          this.file.zoomIn();
          this.disableButton = false;
          break;
        case 'zoomOut':
          this.file.zoomOut();
          this.disableButton = false;
          break;
        case 'removed':
        default:
          this.disableButton = true;
          break;
        }
      },

      /**
       * Check if File type is valid
       */
      onFileTypeMismatch () {
        this.placeholder = 'Please select a jpeg or png file.';
      },

      /**
       * Close Modal
       */
      closeModal() {
        this.uploading = false;
        this.disableButton = true;
        this.$parent.$emit('reload-table');
        this.$emit('close');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/admin/simple-upload";
</style>
